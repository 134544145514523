import './App.css';
import CountDown from './countdown/countdown';


function App() {
    return (
        <div className="App">
            <header className="App-header">

                <h1 style={{
                    margin: "0 0 20px 0",
                    padding: 0,
                    fontSize: "50px",
                    fontWeight: 900,
                    textTransform: "uppercase",
                    textAlign: "center",
                    letterSpacing: "5px",
                    textShadow: "#61dafb 2px 2px 1px"
                }}
                >
                    Dikpal
                </h1>

                <p style={{
                    margin: "0 0 30px 0",
                    padding: 0,
                    fontSize: "16px",
                    fontWeight: 300,
                    textTransform: "uppercase",
                    textAlign: "center",
                    color: "rgb(21 223 255 / 34%)"
                }}
                >
                    Comming soon
                </p>
                <div style={{ margin: 0, padding: 0 }}>
                    <CountDown />
                </div>
            </header>
        </div>
    );
}

export default App;
