import { useEffect, useState } from "react";

import css from "./cd.module.css"


export default function CountDown() {

    const sandbox_date = '06/01/2023'


    const [pageLoaded, setPageLoaded] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);


    const calculateTimeLeft = () => {

        // let year = new Date().getFullYear();

        // let difference = +new Date(`11/30/2022`) - + new Date();
        let difference = +new Date(`${sandbox_date}`) - + new Date();

        let timeLeft = {};

        if (sandbox_date != '' && difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }

    function zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }


    useEffect(() => {

        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
            // console.log(timeLeft);
        }, 1000);

    });


    useEffect(() => {
        setPageLoaded(true)
    }, [])



    if (!pageLoaded) {
        return (
            <div>Loading...</div>
        )
    }


    return (
        <div className={css.wrapper}>

            {timeLeft && timeLeft !== null && Object.values(timeLeft).length > 0 &&

                <div className={css.container}>

                    <div className={css.item}>
                        <strong>{zeroPad(timeLeft.days, 2)}</strong>
                        <span>{`Days`}</span>
                    </div>

                    <div className={css.item}>
                        <strong>{zeroPad(timeLeft.hours, 2)}</strong>
                        <span>{`Hours`}</span>
                    </div>

                    <div className={css.item}>
                        <strong>{zeroPad(timeLeft.minutes, 2)}</strong>
                        <span>{`Minutes`}</span>
                    </div>

                    <div className={css.item}>
                        <strong>{zeroPad(timeLeft.seconds, 2)}</strong>
                        <span>{`Seconds`}</span>
                    </div>

                </div>
            }

        </div>
    )
}